<template>
  <div>
    <b-sidebar
      id="sidebar-query-asaas-id"
      sidebar-class="sidebar-lg"
      :visible="queryAsaasId.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => mutateQueryAsaasIdSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h4>Consultar identificador Asaas</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          <b-form-group label="URL da fatura" label-for="asaasId">
            <b-form-input id="asaasId" v-model="asaasId" />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="querying"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <b-spinner v-if="querying" small />
              {{ querying ? "Verificando..." : "Procurar identificador" }}
            </b-button>
          </div>

          <div
            v-if="queryExecuted && identifier === null"
            class="d-flex mt-2"
          >
            <h5>Identificador não encontrado</h5>
          </div>

          <div v-if="queryExecuted && identifier" class="d-flex flex-column mt-2">
            <h5>Identificador localizado:</h5>
            <span>{{ identifier }}</span>
            <b-button
              class="mt-2"
              variant="outline-primary"
              @click="copyIdentifier()"
            >
              <feather-icon icon="CopyIcon" class="mr-50" />
              Copiar identificador
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormFile,
  BButton,
  BFormInput,
  BFormCheckbox,
} from "bootstrap-vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import useVuelidate from "@vuelidate/core";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { VMoney } from "v-money";
import * as types from "../store/types";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    money: VMoney,
  },
  data() {
    return {
      querying: false,
      queryExecuted: false,
      asaasId: undefined,
      identifier: undefined,
    };
  },
  computed: {
    ...mapGetters({
      queryAsaasId: types.QUERY_ASAAS_ID_SIDEBAR,
    }),
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  methods: {
    ...mapMutations({
      mutateQueryAsaasIdSidebar: types.MUTATE_QUERY_ASAAS_ID_SIDEBAR,
    }),
    ...mapActions({
      getAsaasId: types.GET_ASAAS_ID,
    }),
    onSubmit() {
      this.identifier = undefined;
      this.queryExecuted = false;
      this.querying = true;
      this.getAsaasId({ url: this.asaasId })
        .then((response) => {
          this.identifier = response.data.identifier;
        })
        .catch((err) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao adicionar o recebimento. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            this.queryExecuted = true;
            this.querying = false;
          }, 500);
        });
    },
    clear() {
      this.asaasId = undefined;
      this.identifier = undefined;
      this.queryExecuted = false;
    },
    copyIdentifier() {
      navigator.clipboard.writeText(this.identifier);
      this.toast({
        component: ToastificationContent,
        props: {
          title: "Sucesso!",
          text: `Identificador copiado para a área de transferência: ${this.identifier}`,
          icon: "CheckIcon",
          variant: "success",
        },
      });
      this.mutateQueryAsaasIdSidebar({ visible: false });
      this.clear();
    },
  },
};
</script>
