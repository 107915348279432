<template>
  <div
    :class="[
      { 'dark-theme': skin === 'dark', 'light-theme': skin === 'light' },
    ]"
  >
    <Multiselect
      v-bind="$attrs"
      :id="id"
      v-model="localVModel"
      :options="getOptions"
      placeholder=""
      :group-values="verifyGroupValues"
      :group-label="verifyGroupLabel"
      :group-select="true"
      :multiple="multiple"
      :loading="loading"
      :show-labels="false"
      @input="handleInput"
    >
      <template #noOptions>Essa lista está vazia</template>
      <template #noResult>Nenhum dado encontrado</template>
      <template slot="selection" slot-scope="{ values }"
        ><span
          class="multiselect__single multiselect__morethan"
          v-if="values.length >= 10"
          >{{ localVModel.length }} opções selecionadas</span
        ></template
      >
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  inheritAttrs: false,
  components: {
    Multiselect,
  },

  setup() {
    const { skin } = useAppConfig();
    return { skin };
  },

  props: {
    id: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    takeThis: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      localVModel: this.value,
    };
  },

  computed: {
    getOptions() {
      const { options, takeThis } = this;
      if (this.multiple) {
        return [
          {
            groupLabel: "",
            groupValues: options?.map((group) => ({
              ...group,
              _takeThis: group[takeThis],
            })),
          },
        ];
      }

      return options;
    },

    verifyGroupLabel() {
      return this.multiple ? "groupLabel" : "";
    },

    verifyGroupValues() {
      return this.multiple ? "groupValues" : "";
    },
  },

  methods: {
    handleInput(value) {
      const { takeThis, multiple } = this;
      let transformedValue = [];

      if (multiple) {
        transformedValue = Array.isArray(value)
          ? value.map((option) => option[takeThis])
          : [];
      } else {
        transformedValue = value ? [value[takeThis]] : [];
      }

      this.$emit("input", transformedValue);
    },
  },
};
</script>

<style lang="scss">
@import "~vue-multiselect/dist/vue-multiselect.min.css";
@import "@core/scss/vue/libs/all-select.scss";
</style>
