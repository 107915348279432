<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>Filtros</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="6" class="mb-1">
          <dynamic-select
            id="customerName"
            label="Nome do cliente"
            placeholder="Digite o nome do cliente"
            v-model="customer"
            :options="customers"
            :loading="loading.customers"
            @find="findCustomers"
          />
        </b-col>
        <b-col cols="12" md="2" class="mb-1">
          <b-form-group label="CPF do cliente" label-for="customerCPF">
            <b-form-input
              id="customerCPF"
              v-mask="'###.###.###-##'"
              v-model="customerCPF"
              @find="findCustomers"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4" class="mb-1">
          <b-form-group label="Status" label-for="searchStatus">
            <all-select
              id="searchStatus"
              v-model="status"
              :options="statusList"
              label="name"
              take-this="id"
              track-by="id"
              multiple
              :key="clearSelect"
              :loading="loading.statuses"
              :close-on-select="false"
            ></all-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-row class="justify-content-between">
            <b-col>
              <label>Data</label>
            </b-col>
            <b-col cols="auto">
              <b-card-text class="mb-0">
                <b-button
                  id="initial-report-date"
                  variant="flat"
                  class="btn-icon form-horizontal p-0"
                >
                  <feather-icon
                    icon="InfoIcon"
                    size="18"
                    class="text-muted cursor-pointer"
                  />
                </b-button>
                <b-popover
                  target="initial-report-date"
                  triggers="hover"
                  placement="top"
                >
                  <template #title>
                    <span>Data</span>
                  </template>
                  Data do primeiro pagamento
                </b-popover>
              </b-card-text>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <flat-pickr
                id="searchCallReminder"
                v-model="contractDate"
                class="form-control"
                :config="flatPickrConfig"
                :placeholder="`01 Jan 2020 até 31 Dez ${actualYear}`"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Dias no status" label-for="searchDaysOnStatus">
            <b-form-input
              id="searchDaysOnStatus"
              v-model="daysOnStatus"
              type="number"
              @keyup.enter="search"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" class="mb-1">
          <b-form-group label="Franqueado" label-for="searchFranchise">
            <all-select
              id="searchFranchise"
              v-model="franchise"
              :options="franchises"
              label="name"
              take-this="id"
              track-by="id"
              multiple
              :close-on-select="false"
              :loading="loading.franchises"
              :key="clearSelect"
              @input="consultantChange"
            ></all-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" class="mb-1">
          <b-form-group label="Empresa" label-for="searchPartnerCompany">
            <all-select
              id="searchPartnerCompany"
              v-model="partnerCompany"
              :options="partnerCompanies"
              label="name"
              take-this="id"
              track-by="id"
              multiple
              :key="clearSelect"
              :close-on-select="false"
              :loading="loading.partnerCompanies"
              @input="partnerCompanyChanged"
            ></all-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Produto" label-for="searchProduct">
            <all-select
              id="searchProduct"
              v-model="product"
              :options="products"
              label="name"
              take-this="id"
              track-by="id"
              multiple
              :key="clearSelect"
              :close-on-select="false"
              :loading="loading.products"
              @input="productChanged"
            ></all-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group
            label="Solução financeira"
            label-for="searchFinancialSolution"
          >
            <all-select
              id="searchFinancialSolution"
              v-model="financialSolution"
              :options="financialSolutions"
              label="name"
              take-this="id"
              track-by="id"
              multiple
              :key="clearSelect"
              :close-on-select="false"
              :loading="loading.financialSolutions"
            ></all-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" class="mb-1">
          <b-form-group label="PN" label-for="searchConsultant">
            <all-select
              id="searchConsultant"
              v-model="consultant"
              :options="consultants"
              label="name"
              take-this="id"
              track-by="id"
              multiple
              :key="clearSelect"
              :close-on-select="false"
              :loading="loading.consultants"
              :disabled="franchise.length > 0"
              @input="consultantChange"
            ></all-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group
            label="Tipo de visualização"
            label-for="searchStructureOption"
          >
            <v-select
              id="searchStructureOption"
              v-model="structureOption"
              :reduce="(structure_list) => structure_list.key"
              :options="structures"
              :loading="loading.structures"
              label="name"
              :disabled="hasConsultantSelected"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group label="Id do contrato" label-for="contractID">
            <b-form-input id="contractID" v-model="contractId" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" class="mb-1">
          <b-form-group>
            <template slot="label">&nbsp;</template>
            <b-form-radio-group
              id="structureOption"
              v-model="partnerStructureOption"
              :disabled="franchise.length > 0"
              :hidden="consultant.length == 0"
            >
              <b-form-radio value="structure">Estrutura</b-form-radio>
              <b-form-radio value="own">Próprio</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="mb-2 text-right">
          <b-button
            variant="outline-secondary"
            @click.prevent="clearSearch"
            class="mr-1"
            :disabled="loading.list || spinnerActive"
          >
            Limpar
          </b-button>
          <b-button
            variant="outline-primary"
            @click.prevent="search(1)"
            :disabled="loading.list || spinnerActive"
          >
            <b-spinner v-if="loading.list || spinnerActive" small />
            <feather-icon v-else icon="SearchIcon" class="mr-50" />
            <span>
              {{ loading.list || spinnerActive ? "Aguarde..." : "Pesquisar" }}
            </span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BFormRadioGroup,
  BFormRadio,
  BButton,
  BSpinner,
  BPopover,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import todayButton from "@/helpers/todayButton";
import { mapActions, mapGetters } from "vuex";
import {
  READ_ACTION,
  CREATE_ACTION,
  UPDATE_ACTION,
  DELETE_ACTION,
  CONTRACT_RESOURCE,
} from "@/constants/resources";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import DynamicSelect from "@/modules/shared/components/DynamicSelect";
import AllSelect from "@/modules/shared/components/AllSelect";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import * as accountTypes from "@/modules/account/store/types";
import { STRUCTURE } from "@/constants/structure_options";
import { NINETY_DAYS_IN_MILLISECONDS } from "@/helpers/date_picker";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormRadioGroup,
    BFormRadio,
    BButton,
    BSpinner,
    vSelect,
    BCardText,
    BPopover,
    DynamicSelect,
    AllSelect,
    flatPickr,
  },
  props: {
    search: Function,
    spinnerActive: Boolean,
  },
  data() {
    return {
      loading: {
        list: false,
        customers: false,
        consultants: false,
        statuses: false,
        franchises: false,
        products: false,
        partnerCompanies: false,
        financialSolutions: false,
        structures: false,
      },
      flatPickrConfig: {
        altFormat: "j M Y",
        altInput: true,
        mode: "range",
        dateFormat: "Y-m-d",
        locale: Portuguese,
        plugins: [ShortcutButtonsPlugin(todayButton)],
      },
      clearSelect: null,
      customer: null,
      customerCPF: null,
      contractId: null,
      status: [],
      contractDate: null,
      initialPaymentDate: null,
      endPaymentDate: null,
      franchise: [],
      consultant: [],
      partnerStructureOption: STRUCTURE,
      daysOnStatus: null,
      product: [],
      partnerCompany: [],
      financialSolution: [],
      structureOption: null,
      timer: null,
    };
  },
  setup() {
    const actualYear = new Date().getFullYear();

    return { actualYear };
  },
  computed: {
    ...mapGetters({
      customers: sharedTypes.CUSTOMERS,
      consultants: types.CONSULTANTS,
      statusList: types.STATUS_LIST,
      products: sharedTypes.PRODUCTS,
      partnerCompanies: sharedTypes.PARTNER_COMPANIES,
      financialSolutions: types.FINANCIAL_SOLUTIONS,
      user: accountTypes.USER,
      franchises: sharedTypes.FRANCHISES,
      structures: sharedTypes.STRUCTURE_TYPES,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
    }),
    readContractEnabled: function () {
      return this.$can(READ_ACTION, CONTRACT_RESOURCE);
    },
    createContractEnabled: function () {
      return this.$can(CREATE_ACTION, CONTRACT_RESOURCE);
    },
    updateContractEnabled: function () {
      return this.$can(UPDATE_ACTION, CONTRACT_RESOURCE);
    },
    deleteContractEnabled: function () {
      return this.$can(DELETE_ACTION, CONTRACT_RESOURCE);
    },
    hasConsultantSelected: function () {
      return (
        (this.consultant && this.consultant.length > 0) ||
        (this.franchise && this.franchise.length > 0)
      );
    },
  },
  mounted() {
    let initialDate = new Date();
    const MONTHS_GAP = 3;
    initialDate.setMonth(initialDate.getMonth() - MONTHS_GAP);
    let endDate = new Date();
    this.contractDate = `${initialDate.getFullYear()}-${initialDate.getMonth() + 1}-${initialDate.getDate()} até ${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`;
    this.itemsPerPage = this.initialItemsPerPage;
    this.loading.statuses = true;
    this.getStatusList()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os status para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.statuses = false;
      });
    this.loading.consultants = true;
    this.getConsultants()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os consultores para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.consultants = false;
      });
    this.loading.franchises = true;
    this.getFranchises()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os franqueados para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.franchises = false;
      });
    this.loading.products = true;
    this.getProducts({ has_financial_solution: 0 })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os produtos para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.products = false;
      });
    this.loading.partnerCompanies = true;
    this.getPartnerCompanies({ has_financial_solution: 0 })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as empresas para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.partnerCompanies = false;
      });
    this.loading.financialSolutions = true;
    this.getFinancialSolutions()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as soluções financeiras para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.financialSolutions = false;
      });
    this.getStructures()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as estruturas para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.structures = false;
      });
  },
  methods: {
    ...mapActions({
      searchContracts: types.SEARCH_CONTRACTS,
      getConsultants: types.GET_CONSULTANTS,
      getStatusList: types.GET_STATUS_LIST,
      getProducts: sharedTypes.GET_PRODUCTS,
      getPartnerCompanies: sharedTypes.GET_PARTNER_COMPANIES,
      getFinancialSolutions: types.GET_FINANCIAL_SOLUTIONS,
      getCustomers: sharedTypes.GET_CUSTOMERS,
      getFranchises: sharedTypes.GET_FRANCHISES,
      getStructures: sharedTypes.GET_STRUCTURE_TYPES,
    }),
    findCustomers(keyword) {
      this.loading.customers = true;
      this.getCustomers({ keyword })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os clientes para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.customers = false;
        });
    },
    partnerCompanyChanged(partnerCompany) {
      if (partnerCompany) {
        this.getProducts({
          has_financial_solution: 0,
          partner_company_id: partnerCompany,
        }).catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os produtos. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
      }
    },
    productChanged(product) {
      this.financialSolution = [];
      if (product) {
        this.getFinancialSolutions({
          partner_company_id: this.partnerCompany,
          product_id: product,
        }).catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os produtos. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
      }
    },
    consultantChange() {
      if (
        (this.consultant && this.consultant.length > 0) ||
        (this.franchise && this.franchise.length > 0)
      ) {
        this.structureOption = undefined;
      }
    },
    clearSearch() {
      this.customer = null;
      this.customerCPF = null;
      this.status = [];
      this.contractDate = null;
      this.franchise = [];
      this.consultant = [];
      this.partnerStructureOption = STRUCTURE;
      this.structureOption = null;
      this.daysOnStatus = null;
      this.product = [];
      this.partnerCompany = [];
      this.financialSolution = [];
      this.clearSelect += 1;
      this.contractId = null;
      this.search(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 0;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
