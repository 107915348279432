<template>
  <div>
    <b-sidebar
      id="sidebar-edit-snapshot"
      sidebar-class="sidebar-lg"
      :visible="editSnapshotSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @shown="onShow"
      @change="(val) => mutateEditSnapshotSidebar({ visible: val })"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h4>Editar snapshot do contrato {{ editSnapshotSidebar.id }}</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <b-row class="justify-content-md-center">
          <b-spinner v-if="loading" big />
        </b-row>
        <template v-if="!loading">
          <!-- Form -->
          <b-form
            @submit.prevent="onSubmit"
            @reset.prevent="resetForm"
            class="p-1"
          >
            <b-row>
              <b-col sm="9">
                <label>PN</label>
              </b-col>
              <b-col sm="3">
                <label>Percentual</label>
              </b-col>
            </b-row>
            <div
              class="row"
              v-for="(snapshot, index) in snapshots"
              :key="index"
              style="align-items: flex-end"
            >
              <b-col sm="9">
                <span style="font-size: 10px">{{ snapshot.name }}</span>
                <v-select
                  :id="`snapshot-sidebar-consultant-${index}`"
                  v-model="snapshot.consultant"
                  :options="consultants"
                  @input="inputSelect"
                  :disabled="snapshot.id_consultant_role <= empresaRoleId"
                  label="name"
                >
                  <template #no-options="{ search, searching, loading }">
                    Nenhum PN encontrado neste nível
                  </template>
                </v-select>
              </b-col>
              <b-col sm="3" style="display: flex">
                <b-form-input
                  :id="`snapshot-sidebar-percentage-${index}`"
                  v-model="snapshot.percentage_value"
                  min="0"
                  max="100"
                  type="number"
                  step="0.1"
                />
              </b-col>
              <br /><br />
            </div>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                :disabled="saving"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ saving ? "Salvando..." : "Salvar" }}
              </b-button>
            </div>
          </b-form>
        </template>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import _ from "lodash";
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormFile,
  BAvatar,
  BButton,
  BFormInvalidFeedback,
  BFormInput,
  BRow,
  BCol,
  BSpinner,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupText,
} from "bootstrap-vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import PercentageInput from "@/modules/shared/components/PercentageInput";
import * as types from "../store/types";
import {
  PNH_ROLE_ID,
  PNC_ROLE_ID,
  PNP_ROLE_ID,
  FF_ROLE_ID,
  EMPRESA_ROLE_ID,
} from "@/constants/consultants";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BSpinner,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    PercentageInput,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      snapshots: [],
      loading: false,
      saving: false,
    };
  },
  computed: {
    ...mapGetters({
      editSnapshotSidebar: types.EDIT_SNAPSHOT_SIDEBAR,
      consultants: types.CONSULTANTS,
    }),
    empresaRoleId: function () {
      return EMPRESA_ROLE_ID;
    },
  },
  setup() {
    return { toast: useToast() };
  },
  methods: {
    ...mapMutations({
      mutateEditSnapshotSidebar: types.MUTATE_EDIT_SNAPSHOT_SIDEBAR,
    }),
    ...mapActions({
      updateSnapshots: types.UPDATE_SNAPSHOTS,
      getSnapshots: types.GET_SNAPSHOTS,
      getConsultantsRoles: types.GET_CONSULTANTS_ROLES,
      calculateSnapshotDistribution: types.CALCULATE_SNAPSHOT_DISTRIBUTION,
    }),
    onShow() {
      this.loading = true;
      this.getConsultantsRoles()
        .then((response) => {
          this.snapshots = response.data;
          this.findSnapthost();
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao recuperar os snapshots do contrato. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    findSnapthost() {
      this.getSnapshots(this.editSnapshotSidebar.id)
        .then((response) => {
          this.fillSnapshots(response.data);
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao recuperar os snapshots do contrato. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.saving = true;
      var snapshots = _.filter(this.snapshots, "consultant");
      this.updateSnapshots({
        contractId: this.editSnapshotSidebar.id,
        snapshots,
      })
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "Os snapshots foram salvos!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.mutateEditSnapshotSidebar({ visible: false });
          this.snapshots = [];
        })
        .catch((err) => {
          if (err.response.status == 405) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Não é possivel sincronizar. " + err.response.data,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao salvar os snapshots. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .finally(() => {
          this.saving = false;
        });
    },
    consultantsByRole(role) {
      //PNH pode ser toda empresa
      if (role == PNH_ROLE_ID) {
        return this.consultants;
      } else if (role == PNC_ROLE_ID || role == PNP_ROLE_ID) {
        //PNCS podem ser toda empresa do role PNC ao PNVP
        return _.filter(this.consultants, (element) => {
          return (
            element.id_consultant_role != PNH_ROLE_ID &&
            element.id_consultant_role != FF_ROLE_ID &&
            element.id_consultant_role != EMPRESA_ROLE_ID
          );
        });
      } else {
        // Retornar os consultores de acordo com a role
        return _.filter(this.consultants, (element) => {
          return element.id_consultant_role == role;
        });
      }
    },
    fillSnapshots(data) {
      _.forEach(this.snapshots, (value) => {
        var snap = _.find(data, {
          id_consultant_role: value.id_consultant_role,
        });
        if (!snap) {
          snap = {
            consultant: undefined,
            id: 0,
            id_consultant: 0,
            percentage_value: "0",
          };
        }
        value = _.merge(value, snap);
      });
    },
    inputSelect() {
      this.loading = true;
      _.forEach(this.snapshots, (value) => {
        if (!value.consultant) {
          value.id = 0;
          value.id_consultant = 0;
          value.percentage_value = "0";
        }
      });
      this.loading = false;
    },
    // recalculate(){
    //   this.loading = true
    //   this.calculateSnapshotDistribution({
    //     contractId: this.editSnapshotSidebar.id,
    //     snapshots: this.snapshots
    //   })
    //     .then(response => {
    //         this.snapshots = response.data
    //     })
    //     .catch(() => {
    //       this.toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: "Oops!",
    //           text: "Ocorreu um erro ao recuperar os snapshots do contrato. Entre em contato com o setor de TI.",
    //           icon: "AlertTriangleIcon",
    //           variant: "danger",
    //         },
    //       });
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // },
  },
};
</script>

<style scoped>
.vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(
    100% - 50px
  ); /* change this to `- 40px` if you're supporting a `clearable` field; I was not */
}

.vs__selected {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.vs__search {
  position: absolute;
}

.vs--open .vs__search {
  position: static;
}

.vs__dropdown-option {
  white-space: normal;
}
</style>
