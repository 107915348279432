<template>
  <div>
    <b-sidebar
      id="sidebar-request-deletion"
      sidebar-class="sidebar-lg"
      :visible="requestDeletionSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => mutateRequestDeletionSidebar({ visible: val })"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4>Deletar contrato {{requestDeletionSidebar.id}}</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>




        <b-form
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          A sua exclusão passara uma uma aprovação de backoffice admin, tem certeza que deseja excluir?
          <br>
          <br>
         <b-col cols="12">
          <b-form-group
            label="Informe o motivo da solicitação"
            label-for="description"
          >
            <b-form-textarea
            id="description"
            placeholder="Informe o motivo da solicitação"
            v-model="description"
            ></b-form-textarea>
          </b-form-group>
        </b-col>


          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="saving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ saving ? 'Solicitando exclusão...' : 'Excluir' }}
            </b-button>
          </div>
        </b-form>

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormFile, BButton, BFormInvalidFeedback, BFormInput, BFormTextarea, BCol
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useVuelidate from '@vuelidate/core'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import MoneyInput from '@/modules/shared/components/MoneyInput'
import { moneyToFloat } from '@/helpers/converters'
import * as types from '../store/types'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    MoneyInput,
    BFormTextarea,
    BCol
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      saving: false,
      description: undefined,
      contract_id: undefined
    }
  },
  computed: {
    ...mapGetters({
      requestDeletionSidebar: types.REQUEST_DELETION_SIDEBAR

    })
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  mounted() {

  },
  methods: {
    ...mapMutations({
      mutateRequestDeletionSidebar: types.MUTATE_REQUEST_DELETION_SIDEBAR
    }),
    ...mapActions({
      saveRequestDeletionSidebar : types.SAVE_REQUEST_DELETION_SIDEBAR
    }),
    onSubmit() {
      this.saving = true
      const request = new FormData();
      request.append("contract_id", this.requestDeletionSidebar.id);
      request.append("description", this.description);


      this.saveRequestDeletionSidebar(request)
        .then(response => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Sucesso",
                text: "A solicitação foi adicionado com sucesso!",
                icon: "CoffeeIcon",
                variant: "success",
              },
            });
            this.file = undefined
            this.gross_value = undefined
            this.mutateRequestDeletionSidebar({ visible: false })
            this.requestDeletionSidebar.saveAction(this.requestDeletionSidebar.currentPage)

          })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao adicionar o recebimento. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          })
        .finally(() => {
          this.saving = false;
        });
    }
  }
}
</script>
